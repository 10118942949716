import React, { useState } from 'react';

import { sendTrackingEvent } from '../../../lib/utils';
import ReceiptModal from './ReceiptModal';

import tr from '../../../../translations';
import ClickableDiv from '../../common/ClickableDiv';

import './Receipt.css'

const { SUBSCRIPTION } = require('../../../../config');

const Receipt = ({ transaction }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const sellerString = `${transaction.seller.name} ${transaction.seller.contactEmail}`;
  const dateFormat = tr('date-format');
  const date = new Date(transaction.transactionTime);
  const dateString = date.toLocaleDateString(dateFormat);
  const dateAndTimeString = `${dateString} ${date.toLocaleTimeString(dateFormat)}`;
  const amountString = `${transaction.totalAmount} ${transaction.currency}`;
  const subscriptionType = (transaction.items[0].subscriptionType in SUBSCRIPTION.TYPES) ? 
    SUBSCRIPTION.TYPES[transaction.items[0].subscriptionType] :
    tr('profile.payment-history-subscription');

  function showModal() {
    sendTrackingEvent('receipts_show_one');
    setShow(true);
  }

  return (
    <>
      <ReceiptModal show={show} onClose={handleClose} title={tr('profile.receipt-header')}>
        {tr('profile.receipt-seller')}
        {sellerString}
        <br />
        {tr('profile.receipt-seller-organisation')}
        {transaction.seller.organisationNumber}
        <br />
        {tr('profile.receipt-seller-adress')}
        {transaction.seller.adress}
        <br />
        {tr('profile.receipt-date')}
        {dateAndTimeString}
        <br />
        {tr('profile.receipt-amount')}
        {amountString}
        <br />
        {tr('profile.receipt-subtotal-amount')}
        {`${transaction.subtotalAmount} ${transaction.currency}`}
        <br />
        {tr('profile.receipt-tax-amount')}
        {`${transaction.totalTaxAmount} ${transaction.currency}`}
        <br />
        <br />
        {tr('profile.receipt-items')}
        <br />
        {transaction.items.map((item) => {
          const periodStart = new Date(item.periodStart);
          const periodEnd = new Date(item.periodEnd);
          const periodString = `${periodStart.toLocaleDateString(
            dateFormat
          )} - ${periodEnd.toLocaleDateString(dateFormat)}`;
          const priceString = `${item.totalAmount} ${item.currency} (${tr(
            'profile.receipt-tax-amount'
          )} ${item.taxAmount} ${item.currency})`;
          return `1 ${tr('profile.receipt-item')} Lylli ${subscriptionType} -  ${periodString}\n${priceString}\n`;
        })}
      </ReceiptModal>
      <ClickableDiv className="receipt-card-row" onClick={() => showModal()}>
        <span className="primary">
          {subscriptionType}
          </span>
        <span className="primary to-the-right">
          {transaction.totalAmount} {transaction.currency}
        </span>
        <br/>
        <span className="secondary">&nbsp;</span>
        <span className="secondary to-the-right">{dateString}</span>
      </ClickableDiv>
    </>
  );
};
export default Receipt;
