import React, { useState, useEffect } from 'react';

import Row from 'react-bootstrap/Row';

import Accordion from 'react-bootstrap/Accordion';

// import SubscriptionTestingPage from '../testing/SubscriptionTestingPage';

import hejsanFigure from '../images/hejsan-figur1.png';
import helpFigure from '../images/help-figur1.png';
import abonnemangIcon from '../images/abonnemang-icon.svg';
import betalningIcon from '../images/betalning-icon.svg';
import kontoIcon from '../images/konto-icon.svg';
import downloadIcon from '../images/download-icon.svg';
import paymentHistoryIcon from '../images/payment-history.svg';

import { connect } from 'react-redux';
import { navigate } from 'gatsby-link';

import { PAYMENT_TYPES } from '../constants';

import tr from '../../translations';
import { REGION } from '../../config';

import { sendTrackingEvent } from '../lib/utils';
import { getLoginToken, getSessionId, setLoginToken, removeSessionId } from '../lib/storage';
import {
  cancelSubscription,
  sendUserFeedback,
  createLoginTokenFromSession,
  getSubscription,
  getReceipts,
  getCurrentOffer,
  getCancellationOffer,
  applyDiscountOffer,
  sendRequestSupport,
  getAppToken
} from '../lib/api';

import { Base, Section } from '../components/common';
import SEO from '../components/seo';
import { ChangeEmailModal, ChangePasswordModal, InfoModal } from '../components/common/modals';
import AppLogo from '../components/common/AppLogo';

import './profile.css';
import ProfileCard from '../components/profile/ProfileCard';
import CancelSubscription from '../components/profile/CancelSubscription/CancelSubscription';
import { getSubscriptionPane } from '../components/profile/SubscriptionPane';
import ChangeSubscriptionModal from '../components/common/modals/ChangeSubscriptionModal';
import Receipts from '../components/profile/Receipts/Receipts';
import QRCode from "react-qr-code";

const { CHECKOUT_URL, SUPPORT_MAIL } = require('../../config');

const ProfilePage = ({
  accountSince,
  email,
  paymentType,
  userId,
  userIsLoaded,
  userIsLoggedIn,
}) => {
  const [showEmail, setShowEmail] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showUpdated, setShowUpdated] = useState(false);
  const [showReceiptModal, setShowReceiptModal] = useState(false);
  const [showCancelAppleModal, setShowCancelAppleModal] = useState(false);
  const [showHandleAppleModal, setShowHandleAppleModal] = useState(false);
  const [showHandleChangePaymentAppleModal, setShowHandleChangePaymentAppleModal] = useState(false);
  const [showCancelSubscription, setShowCancelSubscription] = useState(false);
  const [subscriptionData, setSubscriptionData] = useState({});
  const [showChangeSubscription, setShowChangeSubscription] = useState(false);
  const [subscriptionActiveUntil, setSubscriptionActiveUntil] = useState('');
  const [receiptData, setReceiptData] = useState({});
  const [appToken, setAppToken] = useState(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    let lt = urlParams.get('lt');

    if (lt) {
      // Clear any old login info and add new loginToken
      removeSessionId();
      setLoginToken(lt);
    } else {
      lt = getLoginToken();
    }

    if (!lt) {
      removeSessionId();
      window.location.href = '/';
      return;
    }

    getSubscription(getLoginToken(), REGION).then((subData) => {
      console.log("Got subinfo: ", subData);
      if (subData.nextPaymentAt) {
        setSubscriptionActiveUntil(subData.nextPaymentAt);
      }
      setSubscriptionData(subData);
    });
    getReceipts(getLoginToken(), REGION).then((data) => setReceiptData(data));
    
  }, []);

  // if (window.location.search.includes('testSubs')) {
  //   return <SubscriptionTestingPage />;
  // }

  useEffect(() => {
    const lt = getLoginToken();

    if(!email || !lt) return;
    let appTokenFlag = window.localStorage.getItem('appTokenFlag');
    const lylliEmailRegex = /@(?:boksnok|lylli)\b/;

    if(appTokenFlag || (email && lylliEmailRegex.test(email))) {
      getAppToken(lt, REGION).then((data) => setAppToken(data.token));
    }
  }, [email]);


  if (userIsLoaded && !userIsLoggedIn) {
    navigate('/');
    return null;
  }

  const SubscriptionPane = getSubscriptionPane(paymentType);

  const handleEmailShow = () => setShowEmail(true);
  const handlePasswordShow = () => setShowPassword(true);

  const handleEmailClose = (success) => {
    setShowEmail(false);
    success && setShowUpdated(true);
  };
  const handlePasswordClose = (success) => {
    setShowPassword(false);
    success && setShowUpdated(true);
  };
  const handleUpdatedClose = () => setShowUpdated(false);

  const handleShowCancelSubscriptionQuestion = () => {
    setShowCancelSubscription(true);
  };

  const handleCancelSubscriptionQuestionClose = (reloadPage) => {
    setShowCancelSubscription(false);
    if (reloadPage) {
      window.location.reload();
    }
  };
  const handleCancelSubscription = (reasons) => {
    return cancelSubscription(userId, reasons, getSessionId(), paymentType);
  };

  const handleSubmitFeedback = (feedback) => {
    return sendUserFeedback(feedback, getSessionId());
  };

  const handleRequestSupport = (feedback) => {
    return sendRequestSupport(feedback, getSessionId());
  }
  const handleGetCurrentOffer = () => {
    return getCurrentOffer(getSessionId());
  }

  const handleGetCancellationOffer = () => {
    return getCancellationOffer(getSessionId());
  }

  const handleApplyDiscount = (offer) => {
    return applyDiscountOffer(offer, getSessionId());
  }

  const handleCancelAppleModal = () => {
    setShowCancelAppleModal(true);
  };

  const handleCancelAppleModalClose = () => {
    setShowCancelAppleModal(false);
  };

  const handleAppleModal = () => {
    setShowHandleAppleModal(true);
  };

  const handleAppleModalClose = () => {
    setShowHandleAppleModal(false);
  };

  const handleChangePaymentAppleModal = () => {
    setShowHandleChangePaymentAppleModal(true);
  };

  const handleChangePaymentAppleModalClose = () => {
    setShowHandleChangePaymentAppleModal(false);
  };

  const handleReceiptModalClose = () => {
    setShowReceiptModal(false);
  };

  const handleShowCheckout = async () => {
    let loginToken = getLoginToken();
    if (!loginToken) {
      const result = await createLoginTokenFromSession(getSessionId()).catch((e) => {
        console.log(e);
        return {};
      });
      if (result.loginToken) {
        loginToken = result.loginToken;
        setLoginToken(loginToken);
      }
    }

    if (!loginToken) {
      // Todo: Log error that LoginToken could not be generated
    } else {
      window.location.href = `${CHECKOUT_URL}?lt=${loginToken}`;
    }
  };

  const handleShowChangeSubscription = () => {
    setShowChangeSubscription(true);
  };

  const handleChangeSubscriptionClose = (success) => {
    setShowChangeSubscription(false);
    if (success) {
      window.location.reload();
    }
  };

  const onAccordionSelect = (e) => {
    switch (e) {
      case '4':
        sendTrackingEvent('receipts_show_all');
        break;
      default:
    }
  };

  const userAgent = (typeof navigator !== 'undefined') ? navigator.userAgent : ''
  console.log(userAgent);

  const regex = /Boksnok iOS App - For adults/gi;
  const regex2 = /Lylli iOS App - For adults/gi;
  const regex3 = /Lylli Android App/gi;
  const isInApp = (userAgent.match(regex) || userAgent.match(regex2) || userAgent.match(regex3)) ? true : false;

  return (
    <Base hideHeader={isInApp} hideFooter={isInApp} style={{ backgroundColor: 'white' }}>
      <SEO title={tr('profile.title')} />
      <div
        style={{
          backgroundColor: 'white',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Section className="profile">
          <Row>
            <div
              className="profile-info-card"
              style={{ backgroundColor: 'var(--color-lylli-almond' }}
            >
              <div className="profile-info-card-text-container profile-info-card-text-mustard">
                <p className="small" style={{ opacity: '0.6' }}>
                  {tr('profile.welcome-subheader')}
                </p>
                <h3 className="profile-info-card-text-mustard">{tr('profile.welcome-header')}</h3>
              </div>
              <img className="figureImg" alt="" src={hejsanFigure} />
            </div>
          </Row>
          <hr className="section-seperator" />
          <Row>
            <Accordion defaultActiveKey="0" onSelect={(e) => onAccordionSelect(e)}>
              <ProfileCard
                eventKey="0"
                iconSource={abonnemangIcon}
                headerTranslation="profile.subscription-header"
              >
                <SubscriptionPane
                  {...{
                    subscriptionData,

                    onShowCancelSubscriptionQuestion: handleShowCancelSubscriptionQuestion,
                    onShowCheckout: handleShowCheckout,
                    onShowChangeSubscription: handleShowChangeSubscription,

                    onShowCancelAppleModal: handleCancelAppleModal,
                    onShowHandleAppleModal: handleAppleModal,
                  }}
                />
              </ProfileCard>
              <ProfileCard
                eventKey="1"
                iconSource={betalningIcon}
                headerTranslation="profile.payment-header"
              >
                <div>
                  {subscriptionData.subscriptionState === 'CHURN' ? (
                    <>
                      <div>
                        <p className="small">{tr('profile.payment.type-header')}</p>
                        <p>{tr('profile.payment.type-missing')}</p>
                      </div>
                    </>
                  ) : subscriptionData.subscriptionState === 'GRACE' ? (
                    <>
                      {subscriptionData.paymentType ? (
                        subscriptionData.paymentType === 'STRIPE' ? (
                          <div className="payment-section">
                            <div>
                              <p className="small">{tr('profile.payment.type-header')}</p>
                              <p className="red">{tr('profile.payment.type-card')}</p>
                            </div>
                            <button
                              className="change-profile-info-button"
                              onClick={handleShowCheckout}
                            >
                              {tr('profile.changeBtn')}
                            </button>
                          </div>
                        ) : subscriptionData.paymentType === 'APPLE' ? (
                          <div className="payment-section">
                            <div>
                              <p className="small">{tr('profile.payment.type-header')}</p>
                              <p className="red">
                                {subscriptionData.paymentType.charAt(0) +
                                  subscriptionData.paymentType.substring(1).toLowerCase()}
                              </p>
                            </div>
                            <button
                              className="change-profile-i-button"
                              onClick={handleChangePaymentAppleModal}
                            >
                              i
                            </button>
                          </div>
                        ) : subscriptionData.subscriptionType === 'QUARTERLY' ? 
                        (
                          <div className="payment-section">
                            <div>
                              <p className="small">{tr('profile.payment.type-header')}</p>
                              <p className="red">
                                {subscriptionData.paymentType.charAt(0) +
                                  subscriptionData.paymentType.substring(1).toLowerCase()}
                              </p>
                            </div>
                          </div>
                        ) : (
                          <div className="payment-section">
                            <div>
                              <p className="small">{tr('profile.payment.type-header')}</p>
                              <p className="red">
                                {subscriptionData.paymentType.charAt(0) +
                                  subscriptionData.paymentType.substring(1).toLowerCase()}
                              </p>
                            </div>
                            <button
                              className="change-profile-info-button"
                              onClick={handleShowCheckout}
                            >
                              {tr('profile.changeBtn')}
                            </button>
                          </div>
                        )
                      ) : (
                        <></>
                      )}
                    </>
                  ) : subscriptionData.subscriptionState === 'LEAD' ? (
                    <>
                      <button className="subscription-button" onClick={handleShowCheckout}>
                        {tr('profile.subscription.startTrial')}
                      </button>
                    </>
                  ) : subscriptionData.paymentType === 'GOLDEN_TICKET' || subscriptionData.paymentType === 'GIFT_CARD' ? (
                    <>
                      <button className="subscription-button" onClick={handleShowCheckout}>
                        {tr('profile.subscription.addPayment')}
                      </button>
                    </>
                  ) : (
                    /* subscriptionState 'PAYING' or 'TRIAL' */
                    <>
                      {subscriptionData.paymentType ? (
                        subscriptionData.paymentType === 'APPLE' ? (
                          <div className="payment-section">
                            <div>
                              <p className="small">{tr('profile.payment.type-header')}</p>
                              <p>
                                {subscriptionData.paymentType.charAt(0) +
                                  subscriptionData.paymentType.substring(1).toLowerCase()}
                              </p>
                            </div>
                            <button
                              className="change-profile-i-button"
                              onClick={handleChangePaymentAppleModal}
                            >
                              i
                            </button>
                          </div>
                        ) : (
                          <div className="payment-section">
                            <div>
                              <p className="small">{tr('profile.payment.type-header')}</p>
                              <p>
                                {tr(
                                  `profile.payment.type-${(
                                    subscriptionData.paymentMethodType || 'unknown'
                                  ).toLowerCase()}`
                                )}
                              </p>
                            </div>
                            <button
                              className="change-profile-info-button"
                              onClick={handleShowCheckout}
                            >
                              {tr('profile.changeBtn')}
                            </button>
                          </div>
                        )
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </div>
              </ProfileCard>
              <ProfileCard
                eventKey="2"
                iconSource={paymentHistoryIcon}
                headerTranslation="profile.payment-history-header"
              >
                <Receipts data={receiptData} />
              </ProfileCard>
              <ProfileCard
                eventKey="3"
                iconSource={kontoIcon}
                headerTranslation="profile.account-header"
              >
                <div>
                  <p className="small">{tr('profile.account.accountSince-header')}</p>
                  <p>{accountSince}</p>
                </div>
                <hr />
                <div className="profile-info-container">
                  <div>
                    <p className="small">{tr('profile.account.email-header')}</p>
                    <p>{email}</p>
                  </div>
                  <button className="change-profile-info-button" onClick={handleEmailShow}>
                    {tr('profile.changeBtn')}
                  </button>
                </div>
                <hr />
                <div className="profile-info-container">
                  <div>
                    <p className="small">{tr('profile.account.password-header')}</p>
                    <p>********</p>
                  </div>
                  <button className="change-profile-info-button" onClick={handlePasswordShow}>
                    {tr('profile.changeBtn')}
                  </button>
                </div>
              </ProfileCard>
              <ProfileCard
                eventKey="4"
                iconSource={downloadIcon}
                headerTranslation="profile.download-header"
              >
                <div className="stores">
                  {tr('profile.download-text')}
                  <br />
                  <div className="stores-container">
                    <AppLogo trackerName="account-profile" appType="APPLE" region={REGION} theme="light"/>
                    <AppLogo trackerName="account-profile" appType="GOOGLE" region={REGION} theme="light"/>
                    <AppLogo trackerName="account-profile" appType="AMAZON" region={REGION} theme="light"/>
                  </div>
                  {(appToken && appToken.length > 0) &&
                    <div>
                      	<a href={`lylli://login?token=${appToken}&region=${REGION}`}>lylli link</a>
                        <QRCode 
                          value={`lylli://login?token=${appToken}&region=${REGION}`} 
                          viewBox={`0 0 200 200`}
                          size={200}
                          fgColor='#000000'
                      />
                    </div>
                  }
                </div>
              </ProfileCard>
            </Accordion>
          </Row>
          <hr className="section-seperator" />
          {isInApp ? <></> : 
            <Row>
              <div
                className="profile-info-card"
                style={{ backgroundColor: 'var(--color-lylli-light-green)' }}
              >
                <div className="profile-info-card-text-container">
                  <h4 className="profile-info-card-text-green">{tr('profile.help-header')}</h4>
                  <div className="profile-info-card-link-container">
                    <a className="profile-info-card-link dark-green" href={tr('link.faq')}>
                      {tr('profile.help.faq')}
                    </a>
                    <a className="profile-info-card-link" href={'mailto: ' + SUPPORT_MAIL}>
                      {tr('profile.help.support')}
                    </a>
                  </div>
                </div>
                <img src={helpFigure} alt="" className="figureImg" />
              </div>
            </Row>}
        </Section>
      </div>
      <ChangeSubscriptionModal
        show={showChangeSubscription}
        onClose={handleChangeSubscriptionClose}
        current={subscriptionData}
      />
      <ChangeEmailModal show={showEmail} onClose={handleEmailClose} />
      <ChangePasswordModal show={showPassword} onClose={handlePasswordClose} />
      <InfoModal show={showUpdated} onClose={handleUpdatedClose}>
        {tr('accountUpdated')}
      </InfoModal>
      <CancelSubscription
        show={showCancelSubscription}
        onClose={handleCancelSubscriptionQuestionClose}
        onCancelSubscription={handleCancelSubscription}
        onSubmitWrittenFeedback={handleSubmitFeedback}
        activeUntil={subscriptionActiveUntil}
        onGetCurrentOffer={handleGetCurrentOffer}
        onGetCancellationOffer={handleGetCancellationOffer}
        onApplyDiscountOffer={handleApplyDiscount}
        onRequestSupport={handleRequestSupport}
      />

      {/* Cancel Apple subscription modal */}
      <InfoModal show={showCancelAppleModal} onClose={handleCancelAppleModalClose}>
        <h5>{tr('apple.cancelSubscription-header')}</h5>
        <br />
        <p>
          {tr('apple-step1')}
          <br />
          {tr('apple-step2')}
          <br />
          {tr('apple-step3')}
          <br />
          {tr('apple-step4')}
          <br />
          {tr('apple.cancelSubscription-step5')}
        </p>
        <p>
          {tr('apple.getSupport')}
          <a href={'mailto: ' + SUPPORT_MAIL}>{SUPPORT_MAIL}</a>.
        </p>
      </InfoModal>

      {/* Handle Apple subscription modal */}
      <InfoModal show={showHandleAppleModal} onClose={handleAppleModalClose}>
        <h5>{tr('apple.handleSubscription-header')}</h5>
        <br />
        <p>
          {tr('apple-step1')}
          <br />
          {tr('apple-step2')}
          <br />
          {tr('apple-step3')}
          <br />
          {tr('apple-step4')}
          <br />
          {tr('apple.handleSubscription-step5')}
          <br />
        </p>
        <p>
          {tr('apple.getSupport')}
          <a href={'mailto: ' + SUPPORT_MAIL}>{SUPPORT_MAIL}</a>.
        </p>
      </InfoModal>

      {/* Handle change payment type for Apple*/}
      <InfoModal
        show={showHandleChangePaymentAppleModal}
        onClose={handleChangePaymentAppleModalClose}
      >
        <h5>{tr('apple.changeSubscription-header')}</h5>
        <br />
        <p>{tr('apple.changeSubscription-text1')}</p>
        <br />
        <b>{tr('apple.changeSubscription-text2')}</b>
        <p>
          {tr('apple-step1')}
          <br />
          {tr('apple-step2')}
          <br />
          {tr('apple-step3')}
          <br />
          {tr('apple-step4')}
          <br />
          {tr('apple.cancelSubscription-step5')}
        </p>
        <p>
          {tr('apple.getSupport')}
          <a href={'mailto: ' + SUPPORT_MAIL}>{SUPPORT_MAIL}</a>.
        </p>
      </InfoModal>

      {/* Modal for receipts */}
      <InfoModal show={showReceiptModal} onClose={handleReceiptModalClose}>
        {userIsLoaded && paymentType === PAYMENT_TYPES.KLARNA ? (
          <>
            <p>
              {tr('receipts.klarna').text1}
              <a href={tr('link.klarna')} target="_blank" rel="noreferrer">
                {tr('receipts.klarna').linkText}
              </a>
              {tr('receipts.klarna').text2}
            </p>
          </>
        ) : (
          <>
            <p>
              {tr('receipts.contactSupport')['text1']}
              <a href={'mailto: ' + SUPPORT_MAIL}>{SUPPORT_MAIL}</a>
              {tr('receipts.contactSupport')['text2']}
            </p>
          </>
        )}
      </InfoModal>

    </Base>
  );
};

export default connect(
  ({ user: { accountSince, email, paymentType, userId, userIsLoaded, userIsLoggedIn } }) => ({
    accountSince,
    email,
    paymentType,
    userId,
    userIsLoaded,
    userIsLoggedIn,
  }),
  {}
)(ProfilePage);
